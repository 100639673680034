import { lazy, Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { RouteE } from 'shared/config/navigation';
import RootLayout from 'app/ui/root-layout';
import { AuthLayout } from 'widgets/auth-layout';
import { AuthorizedPageLayout } from 'app/ui/authorized-page-layout';
import { AuthCallback } from './callback/auth';
import HowToPage from './how-to';
import HomePage from './home';
import FaqPage from './faq';
import VideoTutorialsPage from './video-tutorials';
import HowToDetailsPage from './how-to/details';
import Loader from 'shared/ui/atoms/loader';

const ProfilePage = lazy(() => import('./profile'));
const NotificationsPage = lazy(() => import('./profile/notifications'));
const SecurityPage = lazy(() => import('./profile/security'));
const ProfileMainPage = lazy(() => import('./profile/main'));
const ProfileBillingHistoryPage = lazy(() => import('./profile/billing-history'));
const ProfileSubscriptionPage = lazy(() => import('./profile/subscription'));
const EventDetailsPage = lazy(() => import('./event/details'));
const EventCreatePage = lazy(() => import('./event/create'));
const VaultPage = lazy(() => import('./vault'));

const SignInPage = lazy(() => import('./sign-in'));
const SignUpPage = lazy(() => import('./sign-up'));
const ForgotPasswordPage = lazy(() => import('./forgot-password'));
const ResetPasswordPage = lazy(() => import('./reset-password'));

const StakeholderListPage = lazy(() => import('./stakeholders/list'));
const StakeholderFormPage = lazy(() => import('./stakeholders/form'));
const GuestListPage = lazy(() => import('./guests/list'));
const GuestFormPage = lazy(() => import('./guests/form'));
const ConfirmEmailPage = lazy(() => import('./confirm-email'));
const VendorCentral = lazy(() => import('./vendor-central'));

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={RouteE.HOME} element={<RootLayout />}>
            <Route element={<AuthorizedPageLayout />}>
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <HomePage />
                        </Suspense>
                    }
                    index
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <VaultPage />
                        </Suspense>
                    }
                    path={RouteE.VAULT}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <FaqPage />
                        </Suspense>
                    }
                    path={RouteE.FAQ}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <HowToPage />
                        </Suspense>
                    }
                    path={RouteE.HOW_TO}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <HowToDetailsPage />
                        </Suspense>
                    }
                    path={RouteE.HOW_TO_ITEM}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <VideoTutorialsPage />
                        </Suspense>
                    }
                    path={RouteE.VIDEO_TUTORIALS}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <StakeholderListPage />
                        </Suspense>
                    }
                    path={RouteE.STAKEHOLDER_MANAGEMENT}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <StakeholderFormPage />
                        </Suspense>
                    }
                    path={RouteE.STAKEHOLDER_ADD}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <StakeholderFormPage />
                        </Suspense>
                    }
                    path={RouteE.STAKEHOLDER_EDIT}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <GuestListPage />
                        </Suspense>
                    }
                    path={RouteE.GUEST_MANAGEMENT}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <GuestFormPage />
                        </Suspense>
                    }
                    path={RouteE.GUEST_ADD}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <GuestFormPage />
                        </Suspense>
                    }
                    path={RouteE.GUEST_MANAGEMENT_EDIT}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <ProfilePage />
                        </Suspense>
                    }
                >
                    <Route
                        path={RouteE.PROFILE}
                        element={<Navigate to={RouteE.PROFILE_MAIN} />}
                    />
                    <Route
                        element={
                            <Suspense fallback={<Loader />}>
                                <ProfileMainPage />
                            </Suspense>
                        }
                        path={RouteE.PROFILE_MAIN}
                    />
                    <Route
                        element={
                            <Suspense fallback={<Loader />}>
                                <NotificationsPage />
                            </Suspense>
                        }
                        path={RouteE.PROFILE_NOTIFICATIONS}
                    />
                    <Route
                        element={
                            <Suspense fallback={<Loader />}>
                                <SecurityPage />
                            </Suspense>
                        }
                        path={RouteE.PROFILE_SECURITY}
                    />
                    <Route
                        element={
                            <Suspense fallback={<Loader />}>
                                <ProfileBillingHistoryPage />
                            </Suspense>
                        }
                        path={RouteE.PROFILE_BILLING_HISTORY}
                    />
                    <Route
                        path={RouteE.PROFILE_SUBSCRIPTION}
                        element={
                            <Suspense fallback={<Loader />}>
                                <ProfileSubscriptionPage />
                            </Suspense>
                        }
                    />
                </Route>
                <Route
                    path={RouteE.EVENT_CREATE}
                    element={
                        <Suspense fallback={<Loader />}>
                            <EventCreatePage />
                        </Suspense>
                    }
                />
                <Route
                    path={`${RouteE.EVENT_DETAILS}/*`}
                    element={
                        <Suspense fallback={<Loader />}>
                            <EventDetailsPage />
                        </Suspense>
                    }
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <VendorCentral />
                        </Suspense>
                    }
                    path={RouteE.VENDOR_CENTRAL}
                />
            </Route>
            <Route element={<AuthLayout />}>
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <SignInPage />
                        </Suspense>
                    }
                    path={RouteE.SIGN_IN}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <SignUpPage />
                        </Suspense>
                    }
                    path={RouteE.SIGN_UP}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <ConfirmEmailPage />
                        </Suspense>
                    }
                    path={RouteE.CONFIRM_EMAIL}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <ForgotPasswordPage />
                        </Suspense>
                    }
                    path={RouteE.FORGOT_PASSWORD}
                />
                <Route
                    element={
                        <Suspense fallback={<Loader />}>
                            <ResetPasswordPage />
                        </Suspense>
                    }
                    path={RouteE.RESET_PASSWORD}
                />
            </Route>
            <Route
                element={
                    <Suspense fallback={<Loader />}>
                        <AuthCallback />
                    </Suspense>
                }
                path={RouteE.AUTH_CALLBACK}
            />
        </Route>
    )
);
