import { EventCard } from 'entities/event/ui/event-card';
import { Grid } from 'shared/ui/atoms/grid';
import { useEventsQuery } from 'entities/event/model/event';
import { Spacing } from 'shared/ui/atoms/spacing';
import { Typography } from 'shared/ui/atoms/typography';
import { pluralize } from 'shared/lib/pluralize';
import { EventsPageHeader } from 'entities/event/ui/events-page-header';
import { useSetAppHeader } from 'widgets/header/model/store';
import { useScreenDetect } from '../../shared/hooks/use-screen-detect';
import { CreateEventButton } from '../../features/event/create-event-button';
import { FlexBox } from 'shared/ui/atoms/flexbox';
import {  useState } from 'react';
import { StyledSearchInput } from './styles';
import useElementDimension from 'shared/hooks/use-element-dimension';

const HomePage = () => {
    const { isLoading, data } = useEventsQuery();
    const { targetRef, dimensions } = useElementDimension<HTMLDivElement>();
    
    const { isMobile } = useScreenDetect();
    const [searchTerm, setSearchTerm] = useState<string>('');
    useSetAppHeader(<EventsPageHeader />);

    const events = searchTerm.trim() 
        ? 
        data?.events.data?.filter((event) => event.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : data?.events.data;

    return (
        <>
            {isMobile && (
                <Spacing margin='16px'>
                    <CreateEventButton stretch />
                </Spacing>
            )}
            <Spacing ref={targetRef} padding='16px' paddingSm='25px'>
                {isMobile ? (
                    <Spacing>
                        <Typography size='h_2' color='black1'>
                            {pluralize(data?.events?.total ?? 0, 'Event')}
                        </Typography>
                    </Spacing>
                ) : (
                    <FlexBox
                        alignItems='flex-end'
                        columnGap='20px'
                        style={{ marginBottom: '25px' }}
                    >
                        <Typography size='h_2' color='black1' style={{ lineHeight: '30px' }}>
                            {pluralize(data?.events?.total ?? 0, 'Event')}
                        </Typography>
                        <StyledSearchInput
                            placeholder='Search by name'
                            onChange={(value) => setSearchTerm(value)}
                            onReset={() => {}}
                        />
                    </FlexBox>
                )}
                <div ref={targetRef}>
                    <Grid
                        xs={1}
                        sm={2}
                        md={2}
                        lg={3}
                        columnGap={25}
                        rowGap={16}
                        rowGapSm={25}
                    >
                        {!!events?.length &&
                        !isLoading && 
                        events.map((event) => (
                            <EventCard key={event.id} data={event} parentWidth={dimensions.width ?? 0} />
                        ))}
                        {!events?.length && !isLoading && (
                            <div>No events found!</div>
                        )}
                    </Grid>
                </div>
            </Spacing>
        </>
    );
};

export default HomePage;
